<template>
  <div>
    <v-card>
      <v-card-title> Grupos de usuários </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="groups"
          disable-pagination
          hide-default-footer
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              class="mr-2"
              title="Editar permissões"
              @click="redirectToUpdatePermissionsPage(item.id)"
              icon
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";

export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: null,
    },
  },
  computed: {},
  data: () => ({
    headers: [
      { text: "Nome", value: "group_name" },
      { text: "Código", value: "group_code" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    groups: [],
  }),
  methods: {
    async loadGroups() {
      try {
        this.loading = true;

        const response = await this.$axios.get(`/admin/usuarios/grupos`);

        this.setGroups(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    setGroups(data) {
      const cpData = { ...data };

      this.groups = cpData.groups.map((u) => {
        return { ...u };
      });
    },
    redirectToUpdatePermissionsPage(id) {
      this.$router.push(`/admin/usuarios/grupos/${id}/editar`);
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      if (Array.isArray(errorHandled))
        errorHandled.map((e) => this.$toast.error(e));
      else this.$toast.error(errorHandled);
    },
  },
  created() {
    this.loadGroups();
  },
};
</script>

<style></style>
